<template>
  <t-split-screen centered>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <h1>{{ $t('pages.reset_password.heading') }}</h1>
      <p class="text--grey">
        {{ $t('pages.reset_password.lead') }}
      </p>

      <t-form class="mt-5" @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper vid="email" :name="$t('fields.email_address')" rules="required|email">
          <t-input
            v-model="form.email"
            native-type="text"
            :placeholder="`${$t('fields.email_address')}...`"
          >
            <t-icon slot="right" :icon="['far', 'envelope']" />
          </t-input>
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="password"
          :name="$t('fields.password.new')"
          rules="required|min:8|password"
        >
          <password-input
            v-model="form.password"
            :placeholder="$t('fields.password.new')"
          />
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="confirmation"
          :name="$t('fields.password.new_confirmation')"
          rules="required|confirmed:password"
        >
          <password-input
            v-model="confirmation"
            :placeholder="`${$t('fields.password.new_confirmation')}...`"
          />
        </t-validation-wrapper>

        <t-form-element>
          <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
            {{ $t('pages.reset_password.actions.reset') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>

    <dashboard-image slot="secondary" name="reset-password" />
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import DashboardImage from '~/components/DashboardImage.vue';
import PasswordInput from '~/components/PasswordInput.vue';

export default Vue.extend({
  components: {
    TValidationWrapper,
    DashboardImage,
    PasswordInput,
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.token) {
      next();
    } else {
      next(from);
    }
  },

  layout: 'standalone',
  middleware: 'guest',

  data() {
    return {
      form: {
        email: null,
        password: null,
        token: this.$route.query.token,
      },

      confirmation: null,
      loading: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$auth.resetPassword(this.form);
        this.$notify.success(this.$t('notifications.password.reset'));
        await this.$router.push({ name: this.$RouteEnum.LOGIN });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
